.slideshow{
    width:100%;
    height:100%;
    position: relative;
    display: block;
}

.slideshow .slideshow-images{
    width:100%;
    display: block;
    overflow:hidden;
    height:400px;;
}

.slideshow .slideshow-images .slideshow-image{
    width:100%;
    height:100%;
    display: none;
}
.slideshow .slideshow-images .slideshow-image.selected{
    display:block;
}


.slideshow .slideshow-images .slideshow-image img{
    object-fit: cover;  
    width:100%;
    height:100%;
    display: block;
}

.slideshow .slideshow-nav{
    position:absolute;
    left:0;
    bottom:0;
    padding:10px;
}

.slideshow .slideshow-nav .dot{
    width:10px;
    height:10px;
    display:inline-block;
    margin-right:10px;
    border-radius:5px;
    background-color:white;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.5);
    cursor:pointer;
}

.slideshow .slideshow-nav .dot.selected{
    background-color:#6bba8b;

}

