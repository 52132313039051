a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font: inherit;
	font-size: 100%;
	vertical-align: baseline
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block
}

body {
  
	line-height: 1
}

ol,
ul {
	list-style: none
}

blockquote,
q {
	quotes: none
}

blockquote::after,
blockquote::before,
q::after,
q::before {
	content: '';
	content: none
}

table {
	border-collapse: collapse;
	border-spacing: 0
}

* {
	-webkit-box-sizing: border-box;
	box-sizing: border-box
}

.app {
	position: relative;
	font-family: Oxygen, sans-serif;
	font-weight: 400;
	min-width: 360px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	min-height: 100vh;
	overflow: hidden;
	font-size: 16px
}

.container {
	max-width: 1480px;
	margin: 0 20px
}

@media (min-width:1170px) {
	.container {
		margin: 0 40px
	}
}

.txt-red {
	color: #eb5757
}

.txt-bold {
	font-weight: 700
}

.header {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding: 12px 16px;
	background-color: #555555
}

@media (min-width:768px) {
	.header {
		padding: 6px 20px;
		-webkit-box-pack: start;
		-ms-flex-pack: start;
		justify-content: flex-start
	}
}

@media (min-width:1300px) {
	.header {
		-webkit-box-pack: end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		background-color: transparent;
		padding: 16px 40px
	}
}

@media (min-width:1920px) {
	.header {
		padding: 16px 30px
	}
}

.header__burger-btn {
	width: 20px;
	height: 20px;
	position: relative;
	-webkit-transform: rotate(0);
	transform: rotate(0);
	-webkit-transition: .5s ease-in-out;
	transition: .5s ease-in-out;
	cursor: pointer
}

.header__burger-btn span {
	display: block;
	position: absolute;
	width: 100%;
	height: 2px;
	background-color: white;
	border-radius: 2px;
	opacity: 1;
	left: 0;
	-webkit-transform: rotate(0);
	transform: rotate(0);
	-webkit-transition: .25s ease-in-out;
	transition: .25s ease-in-out
}

.header__burger-btn span:nth-child(1) {
	top: 0
}

.header__burger-btn span:nth-child(2) {
	top: 9px
}

.header__burger-btn span:nth-child(3) {
	top: 18px
}

.header__burger-btn.open span:nth-child(1) {
	top: 9px;
	-webkit-transform: rotate(135deg);
	transform: rotate(135deg)
}

.header__burger-btn.open span:nth-child(2) {
	opacity: 0;
	left: -20px
}

.header__burger-btn.open span:nth-child(3) {
	top: 9px;
	-webkit-transform: rotate(-135deg);
	transform: rotate(-135deg)
}

@media (min-width:1300px) {
	.header__burger-btn {
		display: none
	}
}

.header__logo-mob {
	width: 98px
}

@media (min-width:1300px) {
	.header__logo-mob {
		margin-left: 20px
	}
}

@media (min-width:768px) {
	.header__logo-mob {
		display: none
	}
}

.header__logo-mob img {
	margin-left: 10px;
	width: 100%
}

.header__controls {
	width: 220px;
	position: absolute;
	z-index: 10;
	top: 54px;
	right: -100%;
	padding: 30px 20px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #555555;
	height: calc(100%);
	-webkit-box-shadow: -5px 0 10px rgba(0, 0, 0, .1);
	box-shadow: -5px 0 10px rgba(0, 0, 0, .1);
	-webkit-transition: right .3s linear;
	transition: right .3s linear
}

.header__controls.open {
	right: 0
}

@media (min-width:768px) {
	.header__controls {
		position: relative;
		top: auto;
		right: auto;
		width: auto;
		padding: 0;
		background-color: transparent;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-shadow: none;
		box-shadow: none;
		margin-left: auto
	}
}

.header__search {
	font-size: 14px;
	height: 20px;
	width: 148px;
	font-family: Oxygen, sans-serif;
	background-color: transparent;
	border: none;
	background-image: url(../images/icon-search.svg);
	background-repeat: no-repeat;
	background-position: 0 center;
	padding-left: 30px
}

.header__search:focus {
	outline: 0
}

@media (min-width:768px) {
	.header__search {
		width: 100px
	}
}

.header__buttons {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	width: 100%;
	border-top: 1px solid #2dafe5;
	padding: 30px 0;
	margin: 30px 0 0
}

@media (min-width:768px) {
	.header__buttons {
		width: auto;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
		border-top: none;
		border-left: 1px solid #2dafe5;
		border-right: 1px solid #2dafe5;
		padding: 0 10px;
		margin: 0 20px
	}
}

.header__btn {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: transparent;
	border: none;
	font-size: 14px;
	margin-bottom: 30px;
	padding: 0 16px;
	width: 100%
}

.header__btn img {
	width: 20px;
	margin-right: 16px
}

.header__btn:hover {
	cursor: pointer;
	-webkit-transform: scale(1.1);
	transform: scale(1.1)
}

@media (min-width:768px) {
	.header__btn {
		margin: 0 10px;
		padding: 0
	}
	.header__btn img {
		margin: 0
	}
	.header__btn span {
		display: none
	}
}

.header__user {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center
}

.header__user.open .header__user-name {
	width: auto;
	height: auto
}

.header__user-name {
	display: none
}

@media (min-width:768px) {
	.header__user-name {
		display: inline-block
	}
}

.header__user-name svg {
	margin-left: 14px
}

.header__user-dropdown-img {
	margin-left:10px;
	width: 40px;
	height: 40px;
	border-radius: 50%
}
@media (max-width:768px) {
	.header__user-dropdown-img{
		width: 40px;
		height: 40px;
		margin-left: 20px;
		display:none
	}
}
.header__user-img {
	width: 30px;
	height: 30px;
	border-radius: 50%
}

@media (min-width:768px) {
	.header__user-img {
		width: 40px;
		height: 40px;
		margin-left: 20px;
		display:none
	}
}

.sidebar {
	position: absolute;
	z-index: 10;
	left: -100%;
	top: 54px;
	width: 220px;
	height: calc(100%);
	padding-top: 15px;
	background-color: #555555;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-shadow: 5px 0 10px rgba(0, 0, 0, .1);
	box-shadow: 5px 0 10px rgba(0, 0, 0, .1);
	-webkit-transition: left .3s linear;
	transition: left .3s linear
}

.sidebar.open {
	left: 0
}

@media (min-width:1300px) {
	.sidebar {
		color: white;
		position: relative;
		left: 0;
		top: 0;
		width: 280px;
		height: calc(100%);
		padding: 0;
		background-color: #555555;
		-ms-flex-preferred-size: 320px;
		flex-basis: 320px;
		-ms-flex-negative: 0;
		flex-shrink: 0;
		-webkit-box-shadow: none;
		box-shadow: none
	}
}

.sidebar__logo {
	display: none
}

.sidebar__logo img {
	width: 100%
}

@media (min-width:1170px) {
	.sidebar__logo {
		display: inline-block;
		margin: 50px 30px
	}
}

@media (min-width:1440px) {
	.sidebar__logo {
		margin: 50px 44px
	}
}

.content {
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1
}

.content__header {
	margin-top: 24px;
	padding-bottom: 30px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center
}

@media (min-width:1170px) {
	.content__header {
		margin-top: 16px
	}
}

.content__header--with-line {
	padding-left: 10px
}

.content__header-breadcrumbs {
	margin-left: 12px;
	font-size: 20px;
	line-height: 25px
}

@media (min-width:768px) {
	.content__header-breadcrumbs {
		margin-left: 18px;
		font-size: 24px;
		line-height: 30px
	}
}

.content__header-breadcrumbs-item {
	position: relative;
	padding-left: 25px
}

.content__header-breadcrumbs-item::before {
	content: '';
	display: inline-block;
	position: absolute;
	left: 0;
	top: 6px;
	width: 9px;
	height: 16px;
	background-image: url(../images/icon-arr-right.svg);
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover
}

@media (min-width:768px) {
	.content__header-breadcrumbs-item:before {
		top: 8px
	}
}

.content__header-buttons {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-left: auto
}

.content__header-btn {
	border: none;
	background-color: transparent;
	display: none;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	font-weight: 700;
	font-size: 16px;
	color: #2dafe5;
	outline: 0;
	margin-left: 30px;
	padding: 0
}

.content__header-btn img {
	margin-right: 10px
}

.content__header-btn:hover {
	cursor: pointer;
	-webkit-transform: scale(1.1);
	transform: scale(1.1)
}

@media (min-width:768px) {
	.content__header-btn {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex
	}
}

.content__header-btn-separate {
	width: 1px;
	height: 20px;
	background-color: #2dafe5;
	margin-left: 30px
}

.info-block {
	/* min-width: 350px; */
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding: 20px;
	line-height: 20px;
	background-color: rgba(2, 11, 14, 0.05)
}

.info-block--orange {
	background: rgba(242, 153, 74, .05)
}

.info-block--green {
	background: rgba(2, 11, 14, 0.05);
	margin-bottom: 20px;
	margin-top: 10px;
	margin-left: auto;
}

.menu {
	width: 100%;
	padding: 0 30px;
}

.menu__item {
	padding: 13px 0
}

.menu__link {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	text-decoration: none;
	color: #CCCCCC;
	font-size: 14px
}

.menu__link--active {
	color: #FFFFFF;
	font-weight: 700
}

.menu__link:hover {
	color: #FFFFFF;
	font-weight: 700
}

.menu__link-img-wrap {
	display: inline-block;
	width: 20px;
	margin-right: 16px;
	text-align: center;
	color: white
}

.menu__link--active .menu-link__icon--active{
	color: #FFFFFF;
	fill: #FFFFFF
}
.menu__link:hover .menu-link__icon--active{
	color: #FFFFFF;
	fill: #FFFFFF
}
.menu-link__icon{
	color: #CCCCCC;
	fill: #CCCCCC;
}

.menu__separator {
	height: 1px;
	margin: 17px 0;
	background-color: white
}

.btn {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	height: 46px;
	padding: 12px 20px;
	text-decoration: none;
	border-radius: 25px;
	white-space: nowrap;
	font-size: 16px;
	line-height: 20px;
	-webkit-transition: .2s all linear;
	transition: .2s all linear;
	outline: 0;
	border: none;
	background-color: #2dafe5;
	color: #fff
}

.btn:hover {
	cursor: pointer
}

.btn-small {
	display: block;
	padding: 4px 16px;
	font-size: 12px;
	border: 1px solid white;
	border-radius: 25px;
	background-color: transparent;
	color: #2dafe5;
	-webkit-transition: .1s all linear;
	transition: .1s all linear;
	line-height: 14px
}

.btn-small--icon svg {
	fill: #2dafe5;
	vertical-align: middle;
	margin-right: 4px
}

.btn-small--icon img {
	margin-right: 4px
}

.btn-small--icon:hover svg {
	fill: #fff
}

.btn-small:hover {
	cursor: pointer;
	background-color: #2dafe5;
	color: #fff
}

.btn-small:focus {
	outline: 0
}

.input {
	border: 1px solid rgba(45, 175, 229, .16);
	background-color: rgba(45, 175, 229, .05);
	padding: 0 15px;
	height: 46px;
	font-family: Oxygen, sans-serif;
	border-radius: 2px;
	font-size: 16px
}

.input--date {
	padding-right: 30px;
	background-image: url(../images/icon-calendar.svg);
	background-repeat: no-repeat;
	background-position: right 15px center
}

.selectric-select {
	border: 1px solid rgba(45, 175, 229, .16);
	min-width: 80px
}

.selectric-select .selectric {
	font-family: Oxygen, sans-serif;
	border: none;
	background: rgba(45, 175, 229, .05)
}

.selectric-select .selectric .label {
	height: 44px;
	line-height: 44px;
	margin: 0 32px 0 16px;
	font-size: 16px
}

.selectric-select .selectric .button {
	width: 8px;
	margin-right: 16px;
	height: 44px;
	background: 0 0
}

.selectric-select .selectric .button::after {
	border: 5px solid transparent;
	border-top-color: #2dafe5;
	border-bottom: none
}

.selectric-select-shop {
	width: 100%;
	border-right: 1px solid #e5e5e5
}

.selectric-select-shop:first-child .selectric {
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px
}

.selectric-select-shop:last-child {
	border-right: none
}

.selectric-select-shop:last-child .selectric {
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px
}

.selectric-select-shop .selectric {
	font-family: Oxygen, sans-serif;
	border: none;
	background: 0 0
}

.selectric-select-shop .selectric .label {
	height: 46px;
	line-height: 46px;
	margin: 0 32px 0 16px;
	font-size: 16px
}

.selectric-select-shop .selectric .button {
	width: 8px;
	margin-right: 16px;
	height: 46px;
	background: 0 0
}

.selectric-select-shop .selectric .button::after {
	border: 5px solid transparent;
	border-top-color: #bbb;
	border-bottom: none
}

.checkbox__input {
	position: absolute;
	width: 1px;
	height: 1px;
	overflow: hidden;
	clip: rect(0 0 0 0)
}

.checkbox__input:checked+.checkbox__custom {
	background-image: url(../images/icon-checked.svg)
}

.checkbox__input:disabled+.checkbox__custom {
	background-color: #e5e5e5;
	border-color: #9d9d9d
}

.checkbox__custom {
	display: block;
	width: 18px;
	height: 18px;
	overflow: hidden;
	border-radius: 4px;
	background-color: #fff;
	background-position: center center;
	background-repeat: no-repeat;
	border: 1px solid #2dafe5
}

.title {
	font-size: 20px;
	line-height: 25px;
	font-weight: 700
}

@media (min-width:768px) {
	.title {
		font-size: 24px;
		line-height: 30px
	}
}
.pagination {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center
}

.pagination__arrow {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	width: 36px;
	height: 36px;
	border: 1px solid #2dafe5;
	border-radius: 4px
}

.pagination__arrow svg {
	fill: #2dafe5
}

.pagination__arrow:hover {
	cursor: pointer;
	background-color: #2dafe5
}

.pagination__arrow:hover svg {
	fill: #fff
}

.pagination__list {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex
}

.pagination__list-item {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	width: 28px;
	height: 28px;
	margin: 0 6px;
	color: #212121;
	text-decoration: none;
	border-radius: 4px
}

.pagination__list-item:hover {
	background-color: #2dafe5;
	color: #fff
}

.pagination__list-item--active {
	background-color: #2dafe5;
	color: #fff
}

.pagination__list-item--disabled:hover {
	background-color: transparent;
	color: #212121
}

.input-search {
	background-color: rgba(45, 175, 229, .05);
	border: 1px solid rgba(45, 175, 229, .16);
	border-radius: 2px;
	font-size: 16px;
	background-image: url(../images/icon-search.svg);
	background-repeat: no-repeat;
	background-position: 20px center;
	padding-left: 55px;
	font-family: Oxygen, sans-serif;
	margin-left: auto;
	height: 46px;
	outline: 0
}

.input-email {
	background-color: rgba(45, 175, 229, .05);
	border: 1px solid rgba(45, 175, 229, .16);
    border-radius: 2px;
    background-size: 22px;
	font-size: 16px;
	background-image: url(../images/icon-letter-log.svg);
	background-repeat: no-repeat;
	background-position: 20px center;
	padding-left: 55px;
	font-family: Oxygen, sans-serif;
	margin-left: auto;
	height: 46px;
	outline: 0
}
.auth-page{
    /* background-image: url(../images/img_admin.png); */
	background-size: 100% 100%;
	background-repeat: no-repeat;
  }
.input-password {
	background-color: rgba(45, 175, 229, .05);
	border: 1px solid rgba(45, 175, 229, .16);
    border-radius: 2px;
    background-size: 22px;
	font-size: 16px;
	background-image: url(../images/icons-lock.svg);
	background-repeat: no-repeat;
	background-position: 20px center;
	padding-left: 55px;
	font-family: Oxygen, sans-serif;
	margin-left: auto;
	height: 46px;
	outline: 0
}

.dashboard {
	position: relative
}

.dashboard__top {
	display: grid;
	grid-template-columns: 1fr 2fr;
	grid-gap: 35px;
	margin-bottom: 35px
}

.dashboard__top-long-wrap {
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 20px
}

.dashboard__top-long {
	border: 1px solid #555555;
	border-radius: 4px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding-left: 20px
}

.dashboard__top-long-title {
	font-weight: 700;
}

.dashboard__top-long-description {
	font-size: 12px
}

.dashboard__top-long-img {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	width: 86px;
	height: 86px;
	background-color: #555555
}

.dashboard__top-small-wrap {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 35px
}

.dashboard__top-small {
	position: relative;
	border: 1px solid #555555;
	border-radius: 4px;
	padding: 28px 0 20px 20px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between
}

.dashboard__top-small::after {
	content: '';
	display: block;
	position: absolute;
	right: 0;
	top: 0;
	width: 10px;
	height: 86px;
	background-color: #555555;
	border-top-right-radius: 4px
}

.dashboard__top-small-header {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center
}

.dashboard__top-small-header img {
	margin-right: 16px
}

.dashboard__top-small-header span {
	font-weight: 700
}

.dashboard__top-small-value {
	font-size: 32px;
	font-weight: 700
}

.dashboard__bottom {
	display: grid;
	grid-template-columns: 1fr 2fr;
	grid-gap: 35px
}

.dashboard__bottom-item-wrap {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 35px
}

.dashboard__bottom-item {
	border: 1px solid #555555;
	border-radius: 4px;
	/* height: 100%; */
}

.dashboard__bottom-item-header {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding-left: 20px
}

.dashboard__bottom-item-title {
	font-weight: 700
}

.dashboard__bottom-item-img {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-color: #555555;
	width: 60px;
	height: 60px
}

.dashboard__bottom-item-table {
	width: 100%;
	font-size: 12px
}

.dashboard__bottom-item-table th {
	background-color: rgba(72, 79, 82, 0.05);
	padding: 16px 20px;
	font-weight: 700;
	text-align: left
}

.dashboard__bottom-item-table td {
	padding: 16px 20px;
	white-space: nowrap;
}

.dashboard__bottom-item-table td a {
	color: #2dafe5;
	text-decoration: none
}

.dashboard__bottom-item-table-status {
	white-space: nowrap
}

.dashboard__bottom-item-table-status img {
	margin-right: 8px;
	vertical-align: middle
}

.dashboard__bottom-item-btn {
	display: block;
	margin: 16px auto 20px;
	padding: 4px 22px;
	font-size: 12px;
	border: 1px solid #2dafe5;
	border-radius: 25px;
	background-color: transparent;
	color: #2dafe5;
	-webkit-transition: .1s all linear;
	transition: .1s all linear
}

.dashboard__bottom-item-btn:hover {
	cursor: pointer;
	background-color: #2dafe5;
	color: #fff
}

.dashboard__bottom-item-btn:focus {
	outline: 0
}

.orders {
	padding: -1px 30px
}

.orders__filters {
	padding: 30px 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex
}

.orders__filters-title {
	font-weight: 700;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding-right: 20px;
	border-right: 1px solid #2dafe5;
	margin-right: 20px
}

.orders__filters-title img {
	margin-right: 10px
}

.orders__filters-item {
	margin-right: 30px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center
}

.orders__filters-item .selectric-select {
	margin-right: 10px
}

.orders__filters-item-title {
	margin-right: 15px
}

.orders__filters-search {
	width: 350px
}

.chat {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	height: calc(100% - 60px);
	max-height: calc(100vh - 208px)
}

.chat__sidebar {
	width: 330px;
	padding: 30px 0;
	background-color: rgba(45, 175, 229, .05)
}

.chat__search {
	width: calc(100% - 60px);
	margin: 0 30px 30px
}

.chat__history-title {
	font-size: 16px;
	margin-bottom: 10px;
	font-weight: 700;
	margin-left: 30px
}

.chat__history-item {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 10px 0 10px 30px
}

.chat__history-item--active {
	background-color: #fff
}

.chat__history-item:hover {
	cursor: pointer;
	background-color: #fff
}

.chat__user-pic {
	position: relative
}

.chat__user-pic img {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	margin-right: 10px
}

.chat__user-pic--online::after {
	content: '';
	position: absolute;
	right: 7px;
	top: 0;
	z-index: 2;
	display: block;
	width: 16px;
	height: 16px;
	border-radius: 50%;
	background-color: #fff
}

.chat__user-pic--online::before {
	content: '';
	position: absolute;
	right: 10px;
	top: 3px;
	z-index: 3;
	display: block;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background-color: #27ae60
}

.chat__history-item-name {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	font-weight: 700;
	font-size: 12px;
	line-height: 15px;
	margin-bottom: 5px
}

.chat__history-item-name-new {
	display: inline-block;
	background-color: #2dafe5;
	font-size: 8px;
	line-height: 14px;
	text-transform: uppercase;
	color: #fff;
	padding: 0 8px;
	border-radius: 10px;
	margin-left: 10px;
	vertical-align: middle
}

.chat__history-item-date {
	font-size: 12px;
	line-height: 15px
}

.chat__content {
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1
}

.chat__header {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 18px 30px;
	border-bottom: 1px solid #e5e5e5
}

.chat__header-user {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center
}

.chat__header-user .chat__user-pic img {
	width: 50px;
	height: 50px
}

.chat__header-user .chat__user-pic--online::after {
	right: 7px;
	top: 0;
	width: 18px;
	height: 18px
}

.chat__header-user .chat__user-pic--online::before {
	right: 10px;
	top: 3px;
	width: 12px;
	height: 12px
}

.chat__header-user-name {
	position: relative;
	font-size: 16px;
	line-height: 20px;
	color: #2dafe5;
	margin-bottom: 5px;
	padding-right: 15px
}

.chat__header-user-name::after {
	content: '';
	position: absolute;
	top: 9px;
	right: 0;
	display: block;
	width: 10px;
	height: 6px;
	background-image: url(../images/icon-arr-dropdown.svg);
	background-position: center center;
	background-repeat: no-repeat
}

.chat__header-user-status {
	font-size: 14px;
	line-height: 18px
}

.chat__buttons {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 20px
}

.chat__btn {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background-color: #2dafe5;
	border: none;
	outline: 0
}

.chat__btn:hover {
	cursor: pointer;
	-webkit-transform: scale(1.1);
	transform: scale(1.1)
}

.chat__btn--white {
	background-color: #fff
}

.chat__messages {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	padding: 0 30px;
	height: calc(100% - 271px);
	overflow-y: scroll
}

.chat__messages-date {
	text-align: center;
	font-size: 14px;
	padding-top: 15px;
	padding-bottom: 30px
}

.chat__message {
	position: relative;
	padding: 12px 20px;
	border-radius: 25px;
	margin-bottom: 45px;
	line-height: 20px
}

.chat__message--left {
	-ms-flex-item-align: start;
	align-self: flex-start;
	background-color: #f2f2f2
}

.chat__message--left .chat__message-time {
	left: 0
}

.chat__message--right {
	-ms-flex-item-align: end;
	align-self: flex-end;
	background-color: rgba(45, 175, 229, .05)
}

.chat__message--right .chat__message-time {
	right: 0
}

.chat__message-text {
	display: block;
	margin-top: 20px
}

.chat__message-text:first-child {
	margin-top: 0
}

.chat__message-time {
	position: absolute;
	bottom: -20px;
	font-size: 12px;
	color: #828282
}

.chat__send-block {
	margin: 0 30px
}

.chat__send-block textarea {
	display: block;
	width: 100%;
	border-top-left-radius: 25px;
	border-top-right-radius: 25px;
	border: 3px solid rgba(45, 175, 229, .05);
	padding: 12px 20px;
	font-size: 16px;
	font-family: Oxygen, sans-serif;
	resize: none;
	min-height: 100px
}

.chat__send-block textarea:focus {
	outline: 0
}

.chat__send-block-footer {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding: 18px 30px;
	background-color: rgba(45, 175, 229, .05);
	border-bottom-left-radius: 25px;
	border-bottom-right-radius: 25px
}

.chat__send-btn {
	width: 110px
}

.chat__send-btn:hover {
	cursor: pointer;
	-webkit-transform: scale(1.1);
	transform: scale(1.1)
}

.payment {
	padding: 0 30px
}

.payment__table {
	margin: 30px 0
}

.payment__under-table {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start
}

.payment__info-block-wrap .info-block {
	margin-bottom: 20px
}

.payment__btn {
	margin-top: 30px;
	margin-left: auto
}

.payment__btn:hover {
	-webkit-transform: scale(1.1);
	transform: scale(1.1)
}

.open-order {
	padding-bottom: 140px;
	position: relative
}

@media (min-width:768px) {
	.open-order {
		padding-bottom: 0
	}
}

@media (min-width:1440px) {
	.open-order {
		padding: 0 30px
	}
}

.open-order__info {
	position: relative
}

.open-order__info .table-mob-filters-btn {
	position: absolute;
	right: 0;
	bottom: 0
}

.open-order__info-number {
	font-size: 18px;
	margin: 20px 0
}

@media (min-width:768px) {
	.open-order__info-number {
		margin: 30px 0
	}
}

.open-order__info-row {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between
}

@media (min-width:768px) {
	.open-order__info-row {
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-box-pack: start;
		-ms-flex-pack: start;
		justify-content: flex-start
	}
}

.open-order__info-item {
	max-width: 340px;
	width: 45%
}

.open-order__info-item:last-child {
	width: 100%;
	margin-top: 24px
}

@media (min-width:768px) {
	.open-order__info-item:last-child {
		width: auto;
		margin-left: auto;
		margin-right: 0;
		-ms-flex-negative: 0;
		flex-shrink: 0
	}
}

@media (min-width:768px) {
	.open-order__info-item {
		margin-right: 60px
	}
}

@media (min-width:1170px) {
	.open-order__info-item {
		margin-right: 80px
	}
}

@media (min-width:1440px) {
	.open-order__info-item {
		margin-right: 60px
	}
}

@media (min-width:1920px) {
	.open-order__info-item {
		margin-right: 75px
	}
}

.open-order__info-item-title {
	font-weight: 700;
	margin-bottom: 10px
}

.open-order__info-item-name {
	display: block;
	margin-bottom: 10px
}

.open-order__info-item-row {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-bottom: 10px
}

.open-order__info-item-row:last-child {
	margin-bottom: 0
}

.open-order__info-item-row-title {
	font-weight: 700;
	margin-right: 4px
}

.open-order__info-item-row-status img {
	margin: 0 6px
}

.open-order__table {
	margin-top: 60px;
	display: none
}

@media (min-width:768px) {
	.open-order__table {
		display: table
	}
}

.open-order__table--mob {
	display: table
}

@media (min-width:768px) {
	.open-order__table--mob {
		display: none
	}
}

.open-order__item {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center
}

.open-order__item img {
	margin-right: 12px
}

.open-order__under-table {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	margin-top: 40px
}

.open-order__total-block {
	text-align: right
}

.open-order__total-block-row {
	text-align: right;
	margin-bottom: 10px
}

.open-order__total-block-row:last-child {
	margin-bottom: 0
}

.open-order__total {
	font-size: 24px;
	font-weight: 700;
	margin-top: 30px
}

.place-and-orders {
	padding: 0 30px
}

.place-and-orders__top {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	margin-top: 30px
}

.place-and-orders__form {
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	max-width: 640px
}

.place-and-orders__form-row {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	margin-bottom: 20px
}

.place-and-orders__form-row:last-child {
	margin-bottom: 0
}

.place-and-orders__form-row .input {
	width: 100%;
	max-width: 420px
}

.place-and-orders__form-select {
	width: 100%;
	max-width: 420px
}

.place-and-orders__form-textarea {
	width: 100%;
	max-width: 420px;
	border: 1px solid rgba(45, 175, 229, .16);
	background-color: rgba(45, 175, 229, .05);
	padding: 4px 15px;
	height: 90px;
	resize: none;
	font-family: Oxygen, sans-serif;
	border-radius: 2px;
	font-size: 16px
}

.place-and-orders__addresses {
	justify-content: center;
	display: grid;
	/* max-width: 440px; */
	/* grid-template-columns: 1fr; */
	grid-gap: 40px;
	padding: 30px;
	border: 1px solid #555;
	border-radius: 4px;
}

.place-and-orders__addresses-item-row {
	margin-top: 10px
}

.place-and-orders__table {
	margin-top: 30px;
	margin-bottom: 12px;
	border-bottom: 1px solid rgba(0, 0, 0, .05)
}

.place-and-orders__under-table {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end
}

.place-and-orders__btn {
	margin-top: 30px;
	margin-left: auto
}

.place-and-orders__btn:hover {
	-webkit-transform: scale(1.1);
	transform: scale(1.1)
}

.shop {
	padding: 0 30px
}

.shop__filters {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin: 30px 0;
	border: 1px solid #e5e5e5;
	border-radius: 4px
}

.shop__grid {
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	grid-gap: 30px
}

.shop__pagination {
	margin-top: 50px;
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	margin-bottom: 30px
}

.shop__recent {
	display: grid;
	grid-template-columns: repeat(7, 1fr);
	grid-gap: 30px;
	padding: 60px 0;
	border-bottom: 1px solid #2dafe5
}

.shop__recent img {
	height: 240px;
	width: 100%;
	-o-object-fit: cover;
	object-fit: cover
}

.shop__news {
	display: grid;
	grid-template-columns: repeat(4, 250px);
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding: 60px 0;
	border-bottom: 1px solid #2dafe5
}

.shop__news-title {
	font-weight: 700;
	font-size: 20px;
	line-height: 1.2;
	margin-bottom: 16px
}

.shop__news-description {
	margin-bottom: 16px;
	line-height: 1.2
}

.shop__socials {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 36px 0
}

.shop__social-link {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	width: 48px;
	height: 48px;
	margin: 0 8px;
	border-radius: 4px;
	border: 1px solid #2dafe5;
	background-color: transparent
}

.shop__social-link svg {
	fill: #2dafe5
}

.shop__social-link:hover {
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
	background-color: #2dafe5
}

.shop__social-link:hover svg {
	fill: #fff
}

.shop__footer {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	padding: 36px 0;
	background-color: rgba(45, 175, 229, .05)
}

.shop__footer-title {
	font-weight: 700;
	font-size: 24px;
	margin-bottom: 28px
}

.shop__subscribe {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 28px
}

.shop__subscribe-input {
	background-color: #fff;
	border: 1px solid #2dafe5;
	border-radius: 4px;
	width: 345px;
	margin-right: 16px;
	font-size: 16px;
	font-family: Oxygen, sans-serif;
	padding: 0 16px
}

.shop-item {
	font-size: 14px
}

.shop-item__top {
	position: relative;
	padding: 8px;
	height: 320px
}

.shop-item__bg {
	position: absolute;
	width: 98px;
	height: 110px;
	border: 1px solid #2dafe5;
	border-radius: 4px
}

.shop-item__bg--top-left {
	top: 0;
	left: 0
}

.shop-item__bg--top-right {
	top: 0;
	right: 0
}

.shop-item__bg--bottom-left {
	bottom: 0;
	left: 0
}

.shop-item__bg--bottom-right {
	bottom: 0;
	right: 0
}

.shop-item__img {
	display: block;
	position: relative;
	z-index: 2;
	border-radius: 4px;
	width: 100%;
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover
}

.shop-item__new {
	position: absolute;
	z-index: 3;
	top: 12px;
	left: 12px;
	display: inline-block;
	color: #fff;
	font-size: 14px;
	background-color: #2dafe5;
	padding: 8px 20px;
	border-radius: 25px
}

.shop-item__options {
	position: absolute;
	z-index: 3;
	bottom: 12px;
	right: 12px;
	display: inline-block;
	background: #f2f2f3;
	border-radius: 25px;
	padding: 8px 20px;
	border: none;
	color: #6a6a6b;
	font-size: 14px
}

.shop-item__options:hover {
	cursor: pointer
}

.shop-item__bottom {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding-top: 12px
}

.shop-item__title {
	font-weight: 700;
	line-height: 18px;
	margin-bottom: 8px
}

.shop-item__cart-btn {
	width: 36px;
	height: 36px;
	border-radius: 6px;
	background-color: #2dafe5;
	border: none
}

.shop-item__cart-btn img {
	width: 14px
}

.shop-item__cart-btn:hover {
	cursor: pointer
}
table.dataTable, table.dataTable th, table.dataTable td {
    box-sizing: content-box;
    font-size: 14px;
    height: 30px;
    vertical-align: middle;
}
