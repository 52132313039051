.news-header{
    color: #ffffff;
    background-color: #555555 !important;
}
.border-news{
    border-color: #555555 !important;
}
.news-date{
    color: #8a8a8a;
}

.news{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 0.5fr));
    margin:0;
    overflow: hidden;
   
    box-sizing: border-box;
}

.news-item{
    color:#393939;
    padding:20px;
   
}


.news-item .news-image{
    width:100%;
    height:400px;
    margin-bottom:20px;
}

.news-item .title{
   color:#393939;
   font-size: 1.692;
}
.news-item .text{
    overflow: hidden;
}

.news-item .subtitle{
    text-transform: uppercase;
}

.news-item .date{
    color:#999;
    font-size:0.923;
}