html,
body {
  height: 100vh;
  font-style: normal;
  font-weight: 500;
  color: #585858;
  font-size: 13px;
  line-height: 16px;
}

.row {
  margin-left: 0px;
  margin-right: 0px;
}
/* 
@media (min-width: 576px) {
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 18%;
  }
}

@media (min-width: 576px) {
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 82%;
  }
} */

.col-sm-10 {
  padding-right: 0px;
  padding-left: 0px;
}

.container {
  max-width: 100%;
}

.container h3{
  margin-bottom:20px;
  padding-right:15px;
  padding-left:15px;
  font-size:1.2rem;
  color:black;
}

.order_div {
  padding: 20px;
  margin-bottom: 25px;
}

@media (max-width: 1000px) {
  .orders__filters-item {
    margin-right: 0px;
    display: flex;
    align-items: center;
    margin: 10px;
  }
}

.header__controls.open {
  right: 0;
  display: flex;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 1170px) {
  .Logo--desktop {
    display: none;
  }
}

.pagination {
  margin-left: auto;
}
dl,
ol,
ul {
  margin-bottom: 0rem;
}

.product-form {
  width: 100%;
  padding-bottom: 0px;
}

.product-text {
  height: 50px;
}

.dataTable {
  border-style: solid;
  border-width: 1.5px;
  border-color: #e8eef1;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 243, 255, 0.05);
}

.check-box {
  width: 80%;
  padding-left: 80px;
}

.purprice-title {
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 16px;
  line-height: 10px;
  margin-bottom: 0px;
  font-weight: bold;
}

.react-datepicker-wrapper {
  width: 100%;
}

.myDatePicker {
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: #ced4da;
  padding: 10px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.myDatePicker:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.product-details {
  padding-bottom: 20px;
}

.btn-small {
  width: 10%;
}

.transport {
  padding-bottom: 50px;
}

.sales-details {
  padding-bottom: 20px;
}

.add-row {
  display: inline-block;
  margin: 0.375rem;
}

.prurprice-dataTable {
  border-style: solid;
  border-width: 1.5px;
  border-color: #e8eef1;
  padding-top: 0px;
  margin-top: 10px;
}

.login-page {
  text-align: center;
}

.login-page-div {
  margin-top: calc(23%);
  /* border-radius: 5px; */
  height: 450px;
  background-color: white;
}

.login-form {
  margin: auto;
  padding-bottom: 30px;
  width: 85%;
}

.login-title {
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 37px;
  padding-top: 55px;
  padding-bottom: 20px;
}

.orders__filters-search {
  width: calc(60%);
}

.sign-in {
  font-size: 15px;
  width: 160px;
  height: 35px;
  margin-bottom: 20px;
  background-color: white;
  color: black;
}

.sign-in:hover {
  font-size: 15px;
  width: 160px;
  height: 35px;
  margin-bottom: 20px;
  background-color: #8b8a8a;
  color: black;
}

.login-side-div {
  height: auto;
  width: 35%;
  background-color: #555555;
  text-align: center;
  position: relative;
}

.login-page-div {
  padding: 0px;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 47em;
  margin-top: -17em;
  margin-left: -24em;
}

.login-side-grad {
  left: 0;
  margin-top: 370px;
}

.error-messages {
  padding: 5px;
  color: #dc2c2c !important;
  font-size: 13px;
  font-weight: 700;
}

.statu-item {
  padding-left: 20px;
}

.dashboard__top {
  padding-top: 20px;
}

.credit-form {
  padding-bottom: 10px;
}

.payment {
  margin-top: 8px;
  width: 60%;
  height: 53%;
}

.file-table {
  height: 260px;
}

.modal_button {
  width: 100px;
}

.vertical-center {
  padding: 0px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  height: auto;
}

.btn {
  text-transform: none;
}

.btn-success.dropdown-toggle {
  background-color: #ffffff !important;
  border-color: #ffffff;
  box-shadow: 0 0 0 0.2rem #ffffff;
}

.btn-success.dropdown-toggle:hover,
.btn-success.dropdown-toggle:focus {
  background-color: #ffffff !important;
  box-shadow: 0 0 0 0.2rem #ffffff;
}

.btn-success.dropdown-toggle:focus {
  background-color: #ffffff !important;
}

.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-success.dropdown-toggle:focus {
  background-color: #ffffff !important;
  box-shadow: 0 0 0 0.2rem #ffffff;
  border-color: #ffffff;
}

.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #ffff;
  border-color: #ffff;
}

.btn-success:not([disabled]):not(.disabled):active,
.btn-success:not([disabled]):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  box-shadow: 0 0 0 0 #ffff;
  background-color: #ffff !important;
}

.btn-light {
  height: 30px;
  border-radius: 4px;
  color: #585858;
  background-color: #ffffff;
  border-color: #e1e1e1;
  height: auto;
}

.btn-light:hover {
  color: #585858;
  background-color: #e2e6ea;
  border-color: #e1e1e1;
}

.btn-light.focus,
.btn-light:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-light.disabled,
.btn-light:disabled {
  color: #585858;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show > .btn-light.dropdown-toggle {
  color: #585858;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled).active:focus,
.btn-light:not(:disabled):not(.disabled):active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.select-lang-class {
  padding-top: 5px;
  padding-right: 5px;
  width: 79px;
  margin-left: 12px;
}

.dataTables_wrapper .dataTables_filter input {
  margin-left: 0.5em;
  /* -webkit-appearance; */
  border-radius: 5px;
  -webkit-appearance: button;
}

.dataTables_wrapper .dataTables_filter input:active {
  margin-left: 0.5em;
  /* -webkit-appearance; */
  border-radius: 5px;
  -webkit-appearance: button;
}

@media (max-width: 768px) {
  .select-lang-class {
    width: 79px;
    padding-bottom: 10px;
  }
}

.react-confirm-alert-button-group {
  justify-content: flex-end;
}

.statu-item {
  cursor: pointer;
}

table.dataTable.no-footer {
  border-bottom: 2px solid #ece9e9;
}

.input-detail {
  margin-left: 20px;
}

.place-and-orders__top {
  border-bottom: 1px solid #2dafe5;
}

.product-detail-table {
  padding-top: 20px;
}

.header__user-img {
  margin-left: 5px;
}

.input-detail {
  width: 100%;
}

.css-1szy77t-control:hover {
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: #495057;
  background-color: #fff;
  border-color: #80bdff !important;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
}

.css-1szy77t-control {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
  border-color: #80bdff !important;
}

:focus {
  outline: -webkit-focus-ring-color 3px;
}

input:focus {
  outline-offset: 0px;
}

.table td,
.table th {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #dee2e6;
}
/* .note-modal{
  margin-top:-200px
} */
.page-loading-spinner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: rgba(255, 255, 255, 0.9);
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}

table.dataTable thead th {
  padding: 10px 18px;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
}

table.dataTable.no-footer {
  border-bottom: 1px solid #ddd;
}

.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #fff !important;
}

.table--striped tbody tr:nth-child(2n) {
  background-color: rgba(49, 83, 97, 0.05);
}

.table thead th {
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
  vertical-align: middle;
}
/* th:first-child {
  border-left: 1px solid #dddddd;
} */
table.dataTable,
table.dataTable th,
table.dataTable td {
  box-sizing: content-box;
  font-size: 14px;
  height: 29px;
  vertical-align: middle;
}

.select-show-class {
  width: 200px;
}

.dashboard-container {
  padding-top: 35px;
  justify-content: space-around;
}

.top_long-payment {
  margin-top: 25px;
}

@media (min-width: 576px) {
  .top__top-small {
    flex: 0 0 25%;
    max-width: 22%;
  }
}

.top-content {
  padding-top: 24px;
}

.top__top-small {
  padding-top: 24px;
}

.dashboard__top-small {
  height: 100%;
}

.logo {
  padding: 40px 30px;
  padding-top: 69px;
}

@media (max-width: 1170px) {
  .logo {
    padding: 0px 10px;
    padding-top: 10px;
  }
}

.menu_item-span {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #cccccc !important;
}

.select-order_view-filter {
  width: 100px;
}

.order_filter {
  vertical-align: middle;
  padding-top: 20px;
  padding-bottom: 20px;
}

.order-filter_date {
  width: 100px;
  margin-left: 10px;
  margin-right: 10px;
}

.login-input-email {
  margin-top: 10px;
  width: 100%;
  height: 42px;
  margin: auto;
}

.login-input-password {
  width: 100%;
  height: 42px;
  margin: auto;
}

.login-select-lang-class {
  width: 100%;
  margin: auto;
}

.order__info-salesNum {
  padding-top: 30px;
  padding-bottom: 10px;
  font-size: 18px;
}

.order__info-bill {
  padding-top: 20px;
}

.sort-style {
  background: none !important;
  border-top: 1px solid #fff !important;
}

.select-invoice_credit-no {
  width: 100px;
  margin-left: 10px;
  margin-right: 10px;
}

.select-search-class {
  width: 200px;
}

.login-logo-img {
  position: absolute;
  top: 70px;
  width: 70%;
  transform: translate(-50%, -50%);
}

.login-side__footer-text {
  position: absolute;
  bottom: 0;
  left: 50%;
  color: #ffffff;
  transform: translate(-50%, -50%);
}

.btn-primary {
  color: #fff;
  height: 30px;
  background-color: #555555;
  border-color: #555555;
  border-radius: 4px;
}

.btn-primary:hover {
  color: #fff;
  background-color: #888888;
  border-color: #888888;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #b9b9b9;
  border-color: #b9b9b9;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #b9c0ce;
  border-color: #b9c0ce;
}
/* .login-select-lang-class .css-bg1rzq-control{
  height: 42px;
} */

.gallery-wrapper{
  width:100%;
  height:300px;
  overflow-y:auto;
  display: block;
  margin-bottom:20px;
}
.gallery{
  padding:20px;
  display:grid;
  /*grid-template-columns: auto auto auto auto;*/
  grid-template-columns: repeat(auto-fit, minmax(100px, auto));
  grid-gap: 10px;
  margin: 0;
}

.gallery > div{
  aspect-ratio: 1;
  cursor: pointer;
  overflow: hidden;
  position: relative;
}
.gallery div.selected{
 outline:3px solid #6bba8b;
}

.gallery div span{
  position: absolute;
  top:0;
  left:0;
  padding:5px;
  font-size:10px;
}

.gallery .primary{
  position: absolute;
  bottom:0px;
  left:0px;
  width:100%;
  background-color:#6bba8b;
  color:white;
  display:none;
  padding:5px;
}
.gallery .primary input{
  vertical-align: middle;
}
.gallery .primary label{
  margin:0;
  padding:0;
  margin-left:5px;
  display: inline-block;
  white-space: nowrap;
}

.gallery div.selected .primary{
  display:block;
}

.gallery img{
  width:100%;
  height:100%;
  object-fit: cover;
}

.placeholder-label {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #b9c0ce;
  padding: 0 0.2em;
  position: absolute;
  top: -0.6em;
  left: 1.8em;
  background-color: white;
}

.placeholder-label__login {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #b9c0ce;
  padding: 0 0.2em;
  position: absolute;
  top: -0.6em;
  left: 0.8em;
  background-color: white;
}

.login-form__control {
  padding: 0px;
}

.login-have__account {
  display: flex;
  padding-top: 20px;
  color: #b9c0ce;
}

.add-icon {
  margin-right: 20px;
  cursor: pointer;
}
.save-icon {
  margin-right: 10px;
  cursor: pointer;
}

.add-icon__deactive {
  color: #eaeaea;
  margin-right: 10px;
}

.table.dataTable thead .sorting {
  background-image: none;
}

.dataTable {
  border-width: 0px;
}

table.dataTable.no-footer {
  border-bottom: 0px solid #111;
}

.table tbody td {
  color: #585858;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  vertical-align: middle;
  border-bottom: 1px solid #eaf0f2;
}

.table th {
  color: #585858;
  padding: 0.75rem;
  vertical-align: middle;
}

.table thead th {
  font-style: normal;
  font-weight: 500 !important;
  font-size: 13px;
  line-height: 16px;
  color: #585858;
  vertical-align: middle;
  border-left: 0px solid #eaf0f2 !important;
  border-top: 0px solid #eaf0f2 !important;
  border-bottom: 1px solid #eaf0f2 !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  color: #333 !important;
  border: 1px solid #e2e3e4;
  border-radius: 4px;
  background-color: #f5f6f7;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
  cursor: default;
  color: #666 !important;
  border: 1px solid transparent;
  background-color: #f5f6f7;
  box-shadow: none;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  box-sizing: border-box;
  display: inline-block;
  min-width: 1.5em;
  padding: 0.5em 0.8em;
  margin-left: 2px;
  text-align: center;
  text-decoration: none !important;
  cursor: pointer;
  cursor: hand;
  border: 1px solid #e2e3e4;
  border-radius: 4px;
  margin-left: 5px;
}

.dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_filter,
.dataTables_wrapper .dataTables_info,
.dataTables_wrapper .dataTables_processing,
.dataTables_wrapper .dataTables_paginate {
  color: #585858;
  padding-top: 20px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: white !important;
  border: 1px solid #585858;
  background-color: #585858;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #585858),
    color-stop(100%, #585858)
  );
  background: -webkit-linear-gradient(top, #585858 0%, #585858 100%);
  background: -moz-linear-gradient(top, #585858 0%, #585858 100%);
  background: -ms-linear-gradient(top, #585858 0%, #585858 100%);
  background: -o-linear-gradient(top, #585858 0%, #585858 100%);
  background: linear-gradient(to bottom, #585858 0%, #585858 100%);
}

.dataTables_wrapper .dataTables_paginate .paginate_button:active {
  outline: none;
  background-color: #585858;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #585858),
    color-stop(100%, #585858)
  );
  background: -webkit-linear-gradient(top, #585858 0%, #585858 100%);
  background: -moz-linear-gradient(top, #585858 0%, #585858 100%);
  background: -ms-linear-gradient(top, #585858 0%, #585858 100%);
  background: -o-linear-gradient(top, #585858 0%, #585858 100%);
  background: linear-gradient(to bottom, #585858 0%, #585858 100%);
  box-shadow: inset 0 0 3px #585858;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
  cursor: default;
  color: #666 !important;
  border: 1px solid transparent;
  background-color: #f5f6f7;
  box-shadow: none;
  display: none;
}

.bottom-datatable {
  display: flex;
}

.dataTables_info {
  margin-left: auto;
  margin-top: 7px;
  margin-right: 20px !important;
}

.dataTables_length select {
  height: 28px;
  width: 46px;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  box-sizing: border-box;
  border-radius: 4px;
  margin-left: 10px;
  margin-right: 10px;
}

.dataTables_length select option {
  border: 1px solid #e2e3e4;
}

.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #fff !important;
}

.active-icon {
  color: #4697d1;
  font-size: 20px;
}

.inactive-icon {
  color: #a3cbe8;
  font-size: 20px;
}

.action-title {
  margin-left: 5px;
  font-weight: 500;
  font-size: 13px;
  font-family: Segoe UI;
  line-height: 25px;
}

@media (min-width: 768px) {
  .slide-form__controls {
    width: 40%;
  }
}

.slide-form__controls {
  height: 100%;
  padding-top: 20px;
  padding-right: 20px;
  padding-left: 15px;
  position: absolute;
  z-index: 10;
  top: 0px;
  right: -100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  height: calc(94%);
  box-shadow: -5px 0 10px rgba(0, 0, 0, 0.1);
  transition: right 0.3s linear;
  position: fixed;
}

.slide-form__controls.open {
  height: 100%;
  right: 0;
  display: flex;
  z-index: 9999;
}

.user-profile {
  width: 100%;
}

.add-product {
  padding-bottom: 40px;
}

.form-control {
  height: 35px;
}

@media (max-width: 540px) {
  .login-side-div {
    display: none;
  }
}

.filter-show__hide {
  display: none;
}

.has-search {
  padding: 0px;
  margin-left: auto;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2rem;
  text-align: center;
  pointer-events: none;
  color: #000;
  margin-top: 2px;
}

.has-search .form-control {
  height: 30px;
  padding-left: 2.375rem;
}

.multi-filter__div {
  /* width: auto!important; */
  margin-left: auto;
  padding-right: 20px;
  min-height: 100px;
  border: solid 1px #eaf0f2;
  border-radius: 5px;
  position: absolute;
  z-index: 10;
  right: 48px;
  margin-top: 40px;
  /* width: 40%; */
  background-color: #fff;
}

.filter-header__option {
  padding: 10;
  width: 20%;
  height: 30px !important;
}

.filter-header__option .css-1wy0on6 {
  min-height: 27px;
  height: 10px;
}

.filter-header__option .css-bg1rzq-control {
  min-height: 27px !important;
}

.filter-header__option .css-1szy77t-control {
  min-height: 27px !important;
}
.order-filter-header__option {
  padding: 10;
  width: 40%;
  height: 30px !important;
}

.order-filter-header__option .css-1wy0on6 {
  min-height: 27px;
  height: 10px;
}

.order-filter-header__option .css-bg1rzq-control {
  min-height: 27px !important;
}

.order-filter-header__option .css-1szy77t-control {
  min-height: 27px !important;
}
.order-page__option {
  padding: 10;
  margin-right: 20px;
  width: 20%;
  height: 30px !important;
}

.order-page__option .css-1wy0on6 {
  min-height: 27px;
  height: 10px;
}

.order-page__option .css-bg1rzq-control {
  min-height: 27px !important;
}

.order-page__option .css-1szy77t-control {
  min-height: 27px !important;
}

.filter-date__picker {
  width: 99%;
  height: 30px;
}

.filter-show__hide {
  display: none;
}

.back-to_signin {
  color: #585858;
  text-decoration: underline;
}

.dashboard-order__icon {
  fill: #fff;
}

.order-open__state {
  background: #99f5a8;
  border-radius: 4px;
  width: 52px;
  padding: 5px 10px;
  color: #138d27;
}

.order-Send__state {
  background: #ebebeb;
  border-radius: 4px;
  width: 52px;
  padding: 5px 10px;
  color: #393939;
}

.css-kj6f9i-menu {
  z-index: 10;
}

.table-responsive {
  overflow-y: hidden;
}

.image__zoom {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  margin-right: 7px;
}

.image__zoom:hover {
  -ms-transform: scale(1.5); /* IE 9 */
  -webkit-transform: scale(1.5); /* Safari 3-8 */
  transform: scale(2);
  margin-right: 15px;
}

.bill-shipping__address {
  display: flex;
  justify-content: space-around;
}

.bill-shipping__address .address-header {
  font-weight: 700;
  margin-top: 12px;
}

.place-order__address {
  height: 100%;
  border: #cccccc solid 1px;
  border-radius: 4px;
  padding-left: 0px;
  padding-top: 10px;
  padding: 13px;
}

.place-order__product-code .active {
  background-color: #f76060;
  color: #fff;
}

.place-order__itemcode-icon {
  margin-left: 10px;
  margin-top: 10px;
  cursor: pointer;
}

.place-order__search-item {
  padding-bottom: 20px;
}

.place-order__search-itemtable {
  max-height: 300px;
  overflow-y: auto !important;
  padding: 0px 30px;
  margin-bottom: 20px;
}

.search-spinner {
  margin-right: 10px;
  color: white !important;
  width: 1.2rem;
  height: 1.2rem;
}

.place-submit__order {
  color: white;
  background: #e15e35;
  border-radius: 4px;
  height: 40px;
  margin-top: -5px;
  float: right;
  border-color: #e15e35;
}

.place-submit__order:hover {
  color: #fff;
  background-color: #ff6434;
  border-color: #ff6434;
}

.place-submit__order:not(:disabled):not(.disabled).active,
.place-submit__order:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #f35321;
  border-color: #f35321;
}

.place-submit__order.disabled,
.place-submit__order:disabled {
  color: #fff;
  background-color: #efae9a;
  border-color: #efae9a;
}

.action-div {
  cursor: pointer;
  color: #004388;
  font-size: 14px;
  font-weight: bold;
}

.place-pattern__form {
  margin-left: auto;
}

.pattern-total__length {
  background: rgba(2, 11, 14, 0.05);
  margin-bottom: 20px;
}

.order-docdue__datepicker {
  height: 35px;
}

.order_detail-table {
  max-height: 100px;
}

.flag-select__options {
  margin-left: 10px;
}

.flag-select__btn:after {
  margin-top: 5px;
}

.flag-select {
  margin-bottom: 6px;
}

.profile-title {
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 37px;
}

.profile-back__icon {
  padding-top: 10px;
}

.profile-back__icon i {
  font-size: 20px;
}

.place_an_orrder-quantity {
  width: 150px;
}

.place_an_orrder-quantity-y {
  width: 150px;
}

.shipping-address_edit {
  float: right;
}

.header__controls button {
  height: 30px;
}

.header__controls .dropdown-menu {
  margin-left: -4px;
}

.flag-select__btn:focus {
  outline: none !important;
}

.dashboard-news {
  margin-top: 20px;
  padding-bottom: 25px;
}

.dashboard-news .news-header {
  padding: 20px 20px;
}

.dashboard-news .dashboard-news_content {
  padding: 0px 20px;
  padding-bottom: 20px;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.pagination-botton {
  box-sizing: border-box;
  display: inline-block;
  min-width: 1.5em;
  padding: 0.5em 0.8em;
  margin-left: 2px;
  text-align: center;
  text-decoration: none !important;
  cursor: pointer;
  cursor: hand;
  border: 1px solid #e2e3e4;
  border-radius: 4px;
  margin-left: 5px;
}

.pagination-botton:hover {
  color: #333 !important;
  border: 1px solid #979797;
}

.pagination-botton:hover {
  color: white !important;
  border: 1px solid #585858;
  background-color: #585858;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #585858),
    color-stop(100%, #585858)
  );
  background: -webkit-linear-gradient(top, #585858 0%, #585858 100%);
  background: -moz-linear-gradient(top, #585858 0%, #585858 100%);
  background: -ms-linear-gradient(top, #585858 0%, #585858 100%);
  background: -o-linear-gradient(top, #585858 0%, #585858 100%);
  background: linear-gradient(to bottom, #585858 0%, #585858 100%);
}

.pagination-botton.active {
  outline: none;
  color: #333 !important;
  background-color: #ededed;
  background: linear-gradient(to bottom, #fff 0%, #dcdcdc 100%);
  box-shadow: inset 0 0 3px #ededed;
}

.pagination select {
  height: 28px;
  width: 46px;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  box-sizing: border-box;
  border-radius: 4px;
  margin-left: 10px;
  margin-right: 10px;
}

.pagination select option {
  border: 1px solid #e2e3e4;
}

.hiden {
  display: none;
}

.item-search__tr {
  cursor: pointer;
}

.item-search__tr:hover {
  background-color: #dddcda;
  cursor: pointer;
}

.item-search__tr-active {
  background-color: #9e9d99;
  color: #000 !important;
  font-size: 14px !important;
  font-weight: bold;
  cursor: pointer;
}
.search__tr-active {
  background-color: #9e9d99;
  color: #000 !important;
  font-size: 14px !important;
  font-weight: bold;
  cursor: pointer;
}
.place-pattern_calculate {
  padding: 25px;
  width: 60%;
}

.place-pattern_calculate .place-order_patter-table {
  padding: 0px 15px;
}

.place-pattern_calculate .place-order_pattern {
  padding-bottom: 20px;
}

.place-pattern_calculate .pattern-calc_equal {
  padding: 0px 15px;
}

.place-pattern_calculate .place-order-calc_form {
  padding: 10px 0px;
}

.place-pattern_calculate .place-order-calc__label {
  padding-left: 0px;
  padding-right: 0px;
}

.place-pattern_calculate .place-order-calc_pattern-div {
  text-align: right;
  padding-top: 12px;
}

.place-order_summary-check {
  padding-bottom: 10px;
}

.summary-submit {
  margin-top: 5px;
}

.place-order_summary-check-div {
  padding-left: 0px;
}

@media (max-width: 768px) {
  .place-pattern_calculate {
    width: 100%;
  }
}

.calculate-deliveryWeek {
  margin-top: 2px;
  float: right;
}

.calculate-deliveryWeek_active {
  margin-top: 2px;
  cursor: pointer;
  float: right;
}
/* .place-an-order_quantity{
  width: 60px;
} */
.place-order-product-code {
  width: 100px;
}

.place-order_description {
  width: 150px;
}

.place-order_Customer-reference {
  width: 150px;
}

.show-all_user {
  padding-top: 20px;
  padding-bottom: 20px;
}

.footer-social__icon {
  margin: 0px 0px;
}

.footer-social__icon img {
  width: 32px;
}

@media(max-width: 765px) {
  .footer-social__icon img {
    width: 27px;
  }
}

.footer-social__icon-woon {
  margin: 5px 10px;
}

.footer-social__icon-woon img {
  height: 32px;
}

.footer-social__icon_image {
  height: 23px;
  font-size: 23px;
  color: #ffffff;
}
.mark_image {
  width: 30px;
  height: 30px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.footer-social-icon-desktop_div {
  height: 50px;
  padding: 0 10px;
}

.social-icon-desktop_div {
  display: flex;
  height: 50px;
  /* padding: 15px 10px; */
  left: 20px;
  position: absolute;
  bottom: 10px;
  width: 82%;
  justify-content: space-around;
}

.footer {
  width: 100%;
  text-align: right;
  position: absolute;
  bottom: 0;
}

.return-order_div {
  max-height: 500px;
  overflow: auto;
}

.return-order__table {
  margin-top: 10px;
}

.return-order-form_header {
  padding-left: 0px;
}

.place-an-order__loop {
  font-size: 13px;
  line-height: 16px;
  color: #586277 !important;
  padding: 0 0.2em;
  position: absolute;
  top: 0.6em;
  right: 1.4em;
  cursor: pointer;
}

.order_div .order-row {
  margin-top: 30px;
}

.place-order-ship-address {
  position: absolute;
  top: -3.6em;
  left: -0.2em;
  width: 100%;
}

@media (max-width: 576px) {
  .place-order-shipping-address {
    margin-top: 80px;
  }
  .place-order-ship-address {
    top: -6.6em;
  }
}

.order_div .col-form-label {
  white-space: nowrap;
}

.place-order-ship-address .product-text {
  padding: 0px;
}

.table.dataTable thead th{
  padding: 10px 8px;
}
.loader {
  background-color: #ffffff;
  bottom: 0;
  left: 0;
  opacity: 0.8;
  position: absolute;
  right: 0;
  top: 0;
}

.loader,
.loader-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-message {
  font-size: 1em;
  margin-top: 8em;
  z-index: 10000;
} 

.white-space-normal {
  white-space: normal;
}

.color-orange {
  color: #e15e35
}